<template>
  <div>
    <div class="app-main flex-column flex-row-fluid " id="kt_app_main">
      <!--begin::Content wrapper-->
      <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content  flex-column-fluid ">
          <!--begin::Content container-->
          <div id="kt_app_content_container" class="app-container  container-xxl ">
            <!--begin::Post card-->
            <div class="card">
              <!--begin::Body-->
              <div class="card-body p-lg-20 pb-lg-0">
                <!--begin::Layout-->
                <div class="d-flex flex-column flex-xl-row">
                  <!--begin::Content-->
                  <div class="flex-lg-row-fluid me-xl-15">
                    <!--begin::Post content-->
                    <div class="mb-17">
                      <!--begin::Wrapper-->
                      <div class="mb-8">

                        <!--begin::Title-->
                        <div href="#" class="text-gray-900 fs-2 fw-bold" style="text-align: center">
                          COOKIE POLICY <br>
                          of <a href="https://app.wantedcrew-int.com ">https://app.wantedcrew-int.com </a>
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Wrapper-->

                      <!--begin::Description-->
                      <div class="fs-5 fw-semibold text-gray-600">

                        <p class="mb-8">
                          <u>Version signature:</u> 1.0
                        </p>
                        <p class="mb-8 text-gray-900">
                          1. What are Cookies?
                        </p>
                        <p class="mb-8">
                          Cookies are small pieces of information that are stored by your web browser on your
                          computer's hard drive. They are often used as a mechanism by websites to remember
                          information about the web browser and the history of its activity of its users, such
                          as clicking various buttons, information on entering the site and using it in recent
                          months or years.
                        </p>
                        <p class="mb-8 text-gray-900">
                          2. How does the website <a href="https://app.wantedcrew-int.com">https://app.wantedcrew-int.com</a>
                          use Cookies and what types of Cookies can you find on it?
                        </p>
                        <p class="mb-8">
                          The website uses them to provide the best possible user experience while using the website
                          and its functionalities. We also use our cookies to develop the website to meet the
                          requirements of our users and their interests, taking into account how users interact
                          with our website, which pages, directories and ads they have visited; how often they
                          visit specific pages and which pages on the site are most popular among our consumers.
                        </p>
                        <p class="mb-8">
                          Our site uses the following types of cookies, some of which require your explicit consent, as set out below:
                        </p>
                        <p class="mb-8 text-gray-900">
                          A) Strictly Necessary Cookies:
                        </p>
                        <p class="mb-8">
                          These are cookies that are necessary for the successful operation and functionality of
                          our website <a href="https://app.wantedcrew-int.com">https://app.wantedcrew-int.com</a> . These include, for example, cookies,
                          which allow users to login. They help make the website work and deliver services that
                          the website visitor expects, like navigating around web pages or accessing secure areas
                          of the website.
                        </p>
                        <p class="mb-8">
                          The preferences cookies are also strictly necessary for the functionality of the
                          website. They help Wanted to remember information that changes the way the site
                          behaves or looks, such as your preferred language or the region you are in or your
                          login details. For instance, by remembering your region, a website may be able to
                          provide you with precise local jobs which you can apply for. These cookies can also assist
                          you in further personalization of the website.
                        </p>
                        <p class="mb-8">
                          Wanted also uses security cookies – cookies used to authenticate users, prevent fraudulent
                          use of login credentials, and protect user data from unauthorized parties.
                        </p>
                        <table class="mb-8">
                          <tr>
                            <th class="fw-bold">N</th>
                            <th class="fw-bold">Cookie name</th>
                            <th class="fw-bold">Domain</th>
                            <th class="fw-bold">Purpose</th>
                            <th class="fw-bold">Term of expiry</th>
                          </tr>
                          <tr>
                            <td class="fw-bold">1.</td>
                            <td class="fw-bold">_hjFirstSeen</td>
                            <td>.wantedcrew-int.com</td>
                            <td>
                              This cookie is used to identify a new user’s first session. It stores the
                              true/false value, indicating whether it was the first
                              time we saw this user.
                            </td>
                            <td>
                              1 hour
                            </td>
                          </tr>
                          <tr>
                            <td class="fw-bold">2.</td>
                            <td class="fw-bold">_hjSessionUser_</td>
                            <td>.wantedcrew-int.com</td>
                            <td>
                              The cookie is set when a user first lands on a page and gives a user a user
                              ID which is unique to that site. It ensures data from subsequent visits to the
                              same site are attributed to the same user ID.
                            </td>
                            <td>
                              1 month
                            </td>
                          </tr>
                          <tr>
                            <td class="fw-bold">3.</td>
                            <td class="fw-bold">_hjAbsoluteSessionInProgres</td>
                            <td>.wantedcrew-int.com</td>
                            <td>
                              This cookie is set to track the beginning of the user's journey for a total
                              session count. It does not contain any identifiable information.
                            </td>
                            <td>
                              1 hour
                            </td>
                          </tr>
                          <tr>
                            <td class="fw-bold">4.</td>
                            <td class="fw-bold">_hjSession_*</td>
                            <td>.wantedcrew-int.com</td>
                            <td>
                              This cookie is set to ensure data from subsequent visits to the same site
                              is attributed to the same user ID, which persists in the Hotjar User ID,
                              which is unique to that site.
                            </td>
                            <td>
                              1 hour
                            </td>
                          </tr>
                        </table>
                        <p class="mb-8 text-gray-900">
                          3. Managing cookies in your browser:
                        </p>
                        <p class="mb-8">
                          In addition to the settings above, you can change your browser's privacy settings to
                          block all cookies; however, this can seriously affect the functionality of the
                          website and its use by you, as many websites may not function properly. Your
                          browser may allow you to delete all cookies when you close it. However, this
                          option also deletes persistent cookies, which can store your preferences and
                          custom settings. It is possible to save the cookies you want as your browser may
                          allow you to specify which sites you always allow or which sites you do not allow
                          the use of cookies. What this means is that you can dismiss cookies from all sites
                          except those that you trust.
                        </p>
                        <p class="mb-8 text-gray-900">
                          4. Changes to our cookie policy:
                        </p>
                        <p class="mb-8">
                          Any future changes to our Cookie Policy will be posted on this page. All changes take effect immediately.
                        </p>
                        <p class="mb-8 text-gray-900">
                          5. Where can I find more information about cookies?
                        </p>
                        <p class="mb-8">
                          If you need more information on cookies and how to manage them, you may be interested to
                          read more here: <a href="https://allaboutcookies.org/">https://allaboutcookies.org/</a>
                        </p>
                      </div>
                      <!--end::Description-->
                    </div>
                    <!--end::Post content-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Layout-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Post card-->
          </div>
          <!--end::Content container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Content wrapper-->
    </div>
  </div>
</template>
<style>
table {
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
  padding: 10px;
}
</style>
<script lang="js">
import {defineComponent} from "vue";

export default defineComponent({
  name: "cookie-policy",
});
</script>
